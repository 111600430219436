<template>
  <div>
    <div class="inside-os">
      <div class="password-os">
        <!-- <el-form :model="form" :rules="rules"> -->
        <table class="com-table">
          <tr>
            <th colspan="2">修改密码</th>
          </tr>
          <tr>
            <td>旧的密码</td>
            <td><input type="text" v-model="form.oldpwd" placeholder="请输入旧的密码" class="oldpass">

            </td>
          </tr>
          <tr>
            <td>新的密码</td>
            <td><input :type="seepsd?'text':'password'" @change="passwordValidate" v-model="form.newpwd" placeholder="请输入新的密码" class="newpass">
              <i class="el-icon-view" @click="seepsd=!seepsd" style="position:relative;left:-14px;top:2px"></i>
              <span v-if="psdBool&&form.newpwd"><em class="error">密码格式有误</em></span>
              <span v-if="!psdBool" class="blue">密码为8-12位的英文和数字组合 </span>
            </td>
          </tr>
          <tr>
            <td>确认密码</td>
            <td><input type="password" v-model="confirmPwd" placeholder="请输入确认密码" class="conpass"></td>
          </tr>
        </table>
        <div class="btn">
          <span class="button" @click="submitForm">确定</span>
          <span class="button" @click="resetForm">重置</span>
        </div>
        <!-- </el-form> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "",
  props: [""],
  data() {
    return {
      seepsd: false,
      confirmPwd: "",
      form: {
        oldpwd: "",
        newpwd: "",
      },
      rules: {
        oldpwd: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
        newpwd: [{ required: true, message: "请输入新密码", trigger: "blur" }],
      },
      // 密码校验
      psdBool: false,
    };
  },

  components: {},
  created() {},
  mounted() {},

  computed: {
    ...mapState(["userInfo"]),
  },

  methods: {
    // 密码校验
    passwordValidate(e) {
      let val = e.target.value;
      // if (val.length < 6) {
      //   this.psdBool = true;
      //   return;
      // }
      // console.log(val.length, this.psdBool);
      let reg = /^[a-z0-9A-Z][a-z0-9A-Z]{7,12}$/;

      if (reg.test(val)) {
        this.psdBool = false;
      } else {
        this.psdBool = true;
      }
    },
    resetForm() {
      this.confirmPwd = "";
      this.form = {};
    },
    submitForm() {
      if (!this.form.oldpwd) {
        this.$message.warning("请输入旧密码");
        return;
      }
      if (!this.form.newpwd) {
        this.$message.warning("请输入新密码");
        return;
      }
      if (this.psdBool) {
        return;
      }
      if (this.confirmPwd != this.form.newpwd) {
        this.$message.warning("两次密码不一致");
        return;
      }
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        resettype: 1,
        oldpwd: this.form.oldpwd,
        newpwd: this.form.newpwd,
      };
      this.$http.post("resetinfo", obj).then((res) => {
        if (res.Status) {
          console.log(res);
          this.$message.success("修改成功");
        } else {
          this.$message.warning(res.Msg);
        }
      });
    },
  },

  watch: {},
};
</script>
<style  scoped>
.blue {
  color: blue;
}
.password-os .btn span {
  width: 80px;
}
.inside-os .button {
  padding: 0 8px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.password-os .btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  padding: 15px 0 0 260px;
  width: 242px;
}
.inside-os .com-table td,
.inside-os .com-table th {
  padding: 4px 0;
  text-align: center;
  font-family: Tahoma, Verdana, Arial, Helvetica, sans-serif;
}
.password-os .com-table th {
  text-align: left;
  padding-left: 10px;
}
.password-os .com-table input {
  width: 230px;
  padding: 0 5px;
}

.inside-os input[type="password"],
.inside-os input[type="text"] {
  height: 26px;
  line-height: 26px;
  border: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px 2px 0 #e8e8e8;
  box-shadow: inset 0 1px 2px 0 #e8e8e8;
  -webkit-appearance: none;
}
.password-os .com-table tr td:last-child {
  text-align: left;
  padding-left: 15px;
}
.password-os .com-table tr td:first-child {
  width: 230px;
  text-align: right;
  padding-right: 15px;
}
.inside-os .com-table {
  width: 100%;
}
.password-os {
  padding-top: 10px;
}
/* .inside-os {
  width: 1018px;
  padding: 0 0 10px 10px;
  color: #4f4d4d;
} */
</style>